import React, { useCallback, useEffect, useState } from 'react'
import styled from '@xstyled/styled-components'
import ImportTemplateCommonForm from '@components/forms/ImportTemplateCommonForm'
import { Trans } from '@lingui/react'
import { Option as SelectOption } from '@mailstep/design-system/ui/Elements/Select/types'
import { useDropzone } from 'react-dropzone'
import { FileSubmit, OnDownloadTemplate } from '@typings/file'
import { InnerWrap, TemplateRow } from './styles'
import { importFileOptions } from '@constants/fileOptions'
import FileDropZone from '@components/elements/FileDropZone'
import Button from '@mailstep/design-system/ui/Elements/Button'
import Status from './components/Status'
import Modal from '@mailstep/design-system/ui/Blocks/Modal'
import ConfirmDialog from '@components/blocks/Dialogs/ConfirmDialog'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal'

export type Props<RV = any> = {
  title: string | JSX.Element
  onImportSubmit: FileSubmit<RV>
  onTemplateDownloadSubmit?: OnDownloadTemplate
  onClose: () => void
  templateOptions?: SelectOption[]
  onImportSuccess?: (response: RV) => void
  confirmDialogText?: string | JSX.Element
}

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  width: 100%;
  margin-bottom: 4;
  margin-top: 4;
`

export const GridImportDialog = <RV,>({
  templateOptions = importFileOptions,
  onImportSubmit,
  onTemplateDownloadSubmit,
  title,
  onClose,
  onImportSuccess,
  confirmDialogText,
}: Props<RV>): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [importError, setImportError] = useState<string | string[] | null>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const { onOpen, isOpen: confirmVisible, onClose: closeDialog } = useModal()

  useEffect(() => {
    if (selectedFile) setImportError(null)
  }, [selectedFile])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const droppedFile = acceptedFiles?.[0]
      setSelectedFile(droppedFile)
    },
  })

  const handleImportSubmit = useCallback(() => {
    if (!selectedFile) return
    setIsUploading(true)
    onImportSubmit(selectedFile).then((fileUpload) => {
      if (!fileUpload.uploadPromise) return
      fileUpload.uploadPromise
        .then((response) => {
          onImportSuccess?.(response)
          onClose()
        })
        .catch((error) => {
          setImportError(error || <Trans id="form.import.error" message="There has been some issues on the way." />)
        })
        .finally(() => {
          setIsUploading(false)
        })
    })
  }, [selectedFile, onClose, onImportSuccess, onImportSubmit])
  return (
    <>
      <Modal
        width="medium"
        title={title}
        titleIcon="import"
        onCancel={onClose}
        isConfirmDisabled={true}
        hasFooter={false}
        hasHeader={true}
      >
        <InnerWrap>
          <TemplateRow>
            <ImportTemplateCommonForm fileOptions={templateOptions} onSubmit={onTemplateDownloadSubmit} />
          </TemplateRow>
          <TemplateRow>
            <Status file={selectedFile} isUploading={isUploading} error={importError} />
          </TemplateRow>
          <FileDropZone getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} onOpen={open} />
          <ButtonWrap>
            <Button
              type="button"
              isLoading={isUploading}
              onClick={confirmDialogText ? onOpen : handleImportSubmit}
              disabled={!!importError || !selectedFile}
            >
              <Trans id="form.import.submit" message="Import files" />
            </Button>
          </ButtonWrap>
        </InnerWrap>
      </Modal>
      {confirmVisible && (
        <ConfirmDialog
          onClose={closeDialog}
          onConfirm={handleImportSubmit}
          title={<Trans id="cornerDialog.warning.title" message="Warning" />}
          text={confirmDialogText}
          onConfirmButtonText={<Trans id="dataGrid.columnValue.yes" message="Yes" />}
          onCloseButtonText={<Trans id="dataGrid.columnValue.no" message="No" />}
        />
      )}
    </>
  )
}
export default GridImportDialog
