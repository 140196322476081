import gql from 'graphql-tag'

const productServicesGetQuery = gql`
  query productServicesGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    productServicesGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: false })
      @rest(type: "productServicesGetQuery", method: "POST", path: "product-service/list") {
      results
    }
  }
`

export default productServicesGetQuery
