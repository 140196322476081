import styled, { css, x } from '@xstyled/styled-components'

const wrapper = css`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  & > *:not(.wrap) {
    margin-top: 1;
    margin-bottom: 2;
  }
`

export const StyledOuterBox = styled(x.div)<{ $topGutter: boolean }>`
  ${wrapper};
  ${({ $topGutter }): string => ($topGutter ? 'margin-top: 29px' : '')};
`
