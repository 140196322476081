import React from 'react'
import { toast } from 'react-toastify'
import Toast, { ToastType } from '@mailstep/design-system/ui/Elements/Toast'

type Props = {
  title?: string
  text?: string
  type: ToastType
}

const cropLength = 300

export const displayToast = ({ title, text, type }: Props): void => {
  const textEllipsis = text ? `${text?.substring(0, cropLength).trim()}${text?.length > cropLength ? '...' : ''}` : ''

  toast(<Toast title={title} text={textEllipsis} type={type} />, {
    type,
    icon: () => null,
    hideProgressBar: true,
    toastId: `${title}${text}`,
  })
}
