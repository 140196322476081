import { useQuery } from '@apollo/client'
import productServicesGetQuery from '@queries/productServicesGetQuery'
import { ApiHookResultWithReload } from '@typings/ApiHooks'
import { ProductService } from '@typings/entities/ProductService'

export const useLoadProductServices = (selectedOrganisation?: string): ApiHookResultWithReload<ProductService[]> => {
  const {
    data,
    loading: isLoading,
    error,
    refetch: reload,
  } = useQuery(productServicesGetQuery, {
    variables: {
      criteria: { organisations: { eq: selectedOrganisation }, active: true },
    },
    fetchPolicy: 'no-cache',
    skip: !selectedOrganisation,
  })
  return {
    data: data?.productServicesGet?.results,
    isLoading,
    error,
    reload,
  }
}
