import React from 'react'
import { DefaultItem as MenuItem } from '@mailstep/design-system/ui/Elements/DropdownMenu/types'
import { HelpCircle1 } from '@mailstep/design-system/ui/Elements/Icon/icons/HelpCircle1'
import MenuDropdown from './MenuDropdown'
import { RoundedBackground } from '@components/elements/RoundedBackground/RoundedBackground'
import { x } from '@xstyled/styled-components'

type Props = {
  menuItems?: MenuItem[]
}

const HelpCenter = ({ menuItems }: Props): JSX.Element => {
  return (
    <MenuDropdown menuItems={menuItems}>
      <x.div cursor="pointer">
        <RoundedBackground>
          <HelpCircle1 />
        </RoundedBackground>
      </x.div>
    </MenuDropdown>
  )
}

export default HelpCenter
