import { PackagingTypeTrans } from '@typings/entities/Product'
import { Option as OptionType } from '@mailstep/design-system/ui/Elements/Select/types'

export const getPackagingTypeOptions = (): OptionType[] => [
  { value: 'A', label: PackagingTypeTrans.A() },
  { value: 'B', label: PackagingTypeTrans.B() },
]

export const salesabilityOptions: OptionType[] = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
]
