export type FileOption = {
  value: string
  label: string
  fileExtension?: string
}

export const fileOptions: FileOption[] = [
  {
    value: 'csv',
    label: 'CSV',
    fileExtension: 'csv',
  },
  {
    value: 'xls',
    label: 'XLSX',
    fileExtension: 'xlsx',
  },
]

export const importFileOptions: FileOption[] = [
  {
    value: 'csv',
    label: 'CSV',
    fileExtension: 'csv',
  },
  {
    value: 'xls',
    label: 'XLS',
    fileExtension: 'xls',
  },
  {
    value: 'xlsx',
    label: 'XLSX',
  },
]
