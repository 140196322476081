import styled from '@xstyled/styled-components'
import { Field } from 'formik'

export const StyledBigField = styled(Field)`
  font-size: 30px;
  color: gray;
  font-weight: semiBold;

  & > div > div > input {
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
  }

  & > div > div > input.left {
    padding: 0 15px;
  }
`
