import React from 'react'
import { Form, Field } from 'formik'
import styled, { x } from '@xstyled/styled-components'
import SelectField from '@components/forms/components/SelectField'
import RadioField from '@components/forms/components/RadioField'
import { FieldLabel } from '@mailstep/design-system/ui/Elements/Label'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import SwitchField from '@components/forms/components/SwitchField'
import Button from '@mailstep/design-system/ui/Elements/Button'
import { FileOption } from '@constants/fileOptions'

type Props = {
  modeSelection: boolean
  anyRowsSelected: boolean
  submitForm?: () => void
  isSubmitting?: boolean
  fileOptions: FileOption[]
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding-bottom: 2;
  min-height: 120px;
`

const RangeWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4;
  ${FieldLabel} {
    margin-bottom: 4;
  }
`

const StyledRadioBox = styled(x.div)`
  & > * {
    margin-bottom: 3;
  }
`

const SwitchWrap = styled.div`
  margin-bottom: 4;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  margin-top: 4;
`

const GridExportForm = ({ modeSelection, anyRowsSelected, submitForm, isSubmitting, fileOptions }: Props): JSX.Element => {
  return (
    <StyledForm>
      <Field
        name="format"
        component={SelectField}
        label={<Trans id="form.fileFormat.label" message="File format" />}
        options={fileOptions}
        spaceAround
      />
      <RangeWrap>
        <div>
          <FieldLabel htmlFor="range">
            <Trans id="form.exportRange.label" message="Export range" />
          </FieldLabel>
        </div>
        <StyledRadioBox flexDirection="column" display="flex">
          <Field
            name="range"
            component={RadioField}
            label={t({ id: 'form.exportRange.filtered', message: 'Filtered items (all)' })}
            value="filtered"
          />
          <Field
            name="range"
            component={RadioField}
            label={t({ id: 'form.exportRange.selected', message: 'Selected items (checked)' })}
            value="selected"
            disabled={!anyRowsSelected}
          />
        </StyledRadioBox>
      </RangeWrap>
      {modeSelection && (
        <SwitchWrap>
          <Field
            name="headersOnly"
            component={SwitchField}
            label={<Trans id="form.exportHeadersOnly.label" message="Headers only" />}
          />
        </SwitchWrap>
      )}
      <ButtonWrap>
        <Button type="button" onClick={submitForm} isLoading={!!isSubmitting}>
          <Trans id="form.buttonSubmit" message="Submit" />
        </Button>
      </ButtonWrap>
    </StyledForm>
  )
}

export default GridExportForm
