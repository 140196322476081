import { Product } from './Product'
import { ExpeditionAsNested, ExpeditionStatusTrans } from './Expedition'
import omit from 'lodash/fp/omit'
import { t } from '@lingui/macro'
import { FlagAppearanceType } from '@constants/FlagAppearance'

export type Reservation = {
  id: string
  booked: number
  lot: string
  product: Product
  expedition?: ExpeditionAsNested
  expedited?: number
  expeditionWms?: {
    id: string
    name: string
  }
  expeditionWarehouse?: {
    id: string
    name: string
  }
}

// statuses impossible for reservations
const excludedStatuses = ['waiting_for_cancel', 'canceled']

export const EditableStatuses = [
  'on_hold',
  'waiting_for_goods',
  'waiting_for_the_carrier',
  'incorrect',
  'stock_ok',
  'carrier_picked_up',
  'ready_to_takeover',
  'delivered',
  'returning',
  'returned',
  'probably_delivered',
]

export const PossibleStatusesTrans = omit(excludedStatuses, ExpeditionStatusTrans)

export type ReservationGridClass = 'new' | 'expedited'

export const statusToVariant: { [value: string]: FlagAppearanceType } = {
  reserved: 'neutralSubtle',
  paired: 'greenSubtle',
  partially: 'purpleSubtle',
  notReserved: 'yellowSubtle',
}
export const ItemReservationStatusTrans = {
  reserved: () => t({ id: 'itemReservationStatus.reserved', message: `Reserved` }),
  paired: () => t({ id: 'itemReservationStatus.paired', message: `Paired` }),
  partially: () => t({ id: 'itemReservationStatus.partially', message: `Partially` }),
  notReserved: () => t({ id: 'itemReservationStatus.notReserved', message: `Not Reserved` }),
}
