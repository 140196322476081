import React, { useState } from 'react'
import { Formik, FormikProps } from 'formik'
import ProductEditForm from './ProductEditForm'
import ProductSetForm from './ProductSetForm'
import { FormikHandleSubmit } from '@typings/Formik'
import { Product, ProductType } from '@typings/entities/Product'
import { Ability, withAcl } from '@components/blocks/Acl'
import { ExternalFormProps } from './types'
import { createValidationSchema, transformRefNumbers } from './validation'

type Props = Omit<ExternalFormProps, 'type'> & {
  initialValues?: Product
  onSubmit: FormikHandleSubmit<Product>
  ability: Ability
  onDownloadAttachment: (id: string, fileName?: string) => Promise<void>
}

const initialValuesEmpty = {}

const ProductEditFormFormik = ({ initialValues = initialValuesEmpty, onSubmit, ability, ...rest }: Props): JSX.Element => {
  const [type, setType] = useState<ProductType>(initialValues.type || 'physical')

  const validateSku = rest.createView || ability.can('supervisorEdit', 'Product')
  const validationSchema = createValidationSchema(type, validateSku)

  const handleOnSubmit: FormikHandleSubmit<Product> = React.useCallback(
    (values, helpers) => {
      return onSubmit(
        {
          ...values,
          expirationOffset: values.expirationOffset || null,
          referenceNumbers: transformRefNumbers(values.referenceNumbers).filter((value) => !!value),
        },
        helpers,
      )
    },
    [onSubmit],
  )

  return (
    <Formik onSubmit={handleOnSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(formikProps: FormikProps<Product>): JSX.Element =>
        formikProps.values.type === 'bundle' || formikProps.values.type === 'virtual' ? (
          <ProductSetForm {...formikProps} {...rest} setType={setType} />
        ) : (
          <ProductEditForm {...formikProps} {...rest} setType={setType} workAroundLot={formikProps.values.workAroundLot} />
        )
      }
    </Formik>
  )
}

export default withAcl(ProductEditFormFormik)
