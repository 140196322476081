import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'expeditionsGrid'

const fetchModule = createFetchModule('@store/ExpeditionsGrid', 'LOAD', moduleName)
const fetchFiltersSubmodule = createFetchSubmodule('@store/ExpeditionsGrid', 'FILTERS', moduleName)
const exportExpeditionsSubmodule = createFetchSubmodule('@store/ExpeditionsGrid', 'EXPORT', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  setSelectedEshop: `@store/ExpeditionsGrid/SET_ESHOP`,
  exportExpeditions: exportExpeditionsSubmodule.actionTypes,
  loadAsyncFilters: fetchFiltersSubmodule.actionTypes,
}

const ownReducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setSelectedEshop:
        draft.eshop = action?.payload
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, fetchFiltersSubmodule.reducer, ownReducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  setSelectedEshop: (eshop: string) => ({ type: actionTypes.setSelectedEshop, payload: eshop }),
  exportExpeditions: exportExpeditionsSubmodule.actions.run,
  loadAsyncFilters: fetchFiltersSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  ...fetchFiltersSubmodule.selectors,
}
