import gql from 'graphql-tag'

export const productDetailFields = `
  active
  createdAt
  eshops
  expectedTurnover
  expirationOffset
  localisation
  organisation
  height
  intHeight
  id
  images
  intImages
  internalSku
  length
  intLength
  name
  packagingType
  productSku
  productGroup
  services
  referenceNumbers
  weight
  intWeight
  width
  intWidth
  workAroundLot
  workAroundSnIn
  hsCode
  countryOfOrigin
  description
  customsDescription
  type
  childrenProducts
  canToggleLotEvidence
  requiresAdditionalWork
  requiresQualitativeReceiving
`

const productGetQuery = gql`
  query productGet($id: Int!) {
    productGet(id: $id) @rest(type: "ProductResult", method: "GET", path: "product/{args.id}") {
      ${productDetailFields}
    }
  }
`

export default productGetQuery
