import React from 'react'
import CommonGrid from '@mailstep/design-system/ui/Blocks/CommonGrid'
import GridExport, { Props as GridExportProps } from '@components/blocks/GridExport'
import { GridProps } from '@mailstep/design-system/ui/Blocks/CommonGrid/types'
import { useModal } from '@mailstep/design-system/ui/Blocks/Modal/hooks/useModal'
import useShallowRouting from './useShallowRouting'

type StyledCommonGridProps = {
  onExport?: GridExportProps['onExport']
}

export type Props = GridProps & StyledCommonGridProps

export type PageGridProps = Omit<
  GridProps,
  'rowsData' | 'totalRowsCount' | 'columnsDefinitions' | 'actionColumnDefinition' | 'minColumnWidth' | 'columnLayout'
> &
  StyledCommonGridProps

const StyledCommonGrid = ({ onExport, gridSelectors, gridActions, ...props }: Props): JSX.Element => {
  const { onOpen: onExportClick, isOpen: exportFormVisible, onClose: hideExport } = useModal()

  const { changePathOrSetPage, rowsPerPageValue } = useShallowRouting(gridActions, gridSelectors)

  return (
    <>
      <CommonGrid
        processCheckedValues={onExportClick}
        gridActions={gridActions}
        gridSelectors={gridSelectors}
        queryRowsParam={rowsPerPageValue}
        customPaginationHandler={changePathOrSetPage}
        {...props}
      />
      {!!onExport && exportFormVisible && <GridExport onClose={hideExport} onExport={onExport} gridSelectors={gridSelectors} />}
    </>
  )
}

export default StyledCommonGrid
